
import rules from "@/mixins/rules"
import Button from "@/components/base/Button"
import contactService from "@/services/contact.service"

export default {
    name: "ContactForm",
    components: { Button },
    mixins: [rules],
    emits: ["success", "error"],
    props: {
        contextPrefix: {
            type: String,
            default: null,
        },
        meta: {
            type: [Object, String],
            default: null,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        prefilledText: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        routeToDatenschutz: {
            type: String,
            default: "/datenschutz",
        },
    },
    data() {
        return {
            confirmDataRegulations: false,
            messageForm: false,
            messageSent: false,
            messageAlert: false,
            messageAlertType: "error",
            messageAlertText: "",
            email: "",
            name: "",
            message: "",
            isSending: false,
        }
    },
    computed: {
        inputFieldColor() {
            return this.dark ? "white" : "black"
        },
    },
    watch: {
        prefilledText: {
            immediate: true,
            handler(value) {
                this.message = value
            },
        },
    },
    methods: {
        toReadableString(json) {
            return JSON.stringify(json, (key, value) => value || "", 4).replace(
                /"([^"]+)":/g,
                "$1:"
            )
        },
        async sendMessage() {
            if (!this.$refs.messageForm.validate() || !this.message) return
            this.isSending = true
            let message
            if (this.meta) {
                message = `Nachricht: ${
                    this.message
                } - Meta: ${this.toReadableString(this.meta)}`
            } else {
                message = this.message
            }
            const prefix = this.contextPrefix ? this.contextPrefix + ": " : ""
            const context = `${prefix}${window.location.href}`
            await contactService
                .sendContact(context, {
                    email: this.email,
                    name: this.name,
                    message: message,
                    ...this.options,
                })
                .then((result) => {
                    this.messageAlertText = result.message
                    this.messageAlertType = result.success ? "success" : "error"
                    this.messageAlert = true
                    this.messageSent = result.success
                    this.message = ""
                    this.$emit("success", result.message)
                })
                .catch((err) => {
                    this.messageAlertText =
                        "Es gab ein Problem beim Senden der Nachricht. Bitte später erneut versuchen."
                    this.messageAlertType = "error"
                    this.messageAlert = true
                    this.$emit("error", err)
                })
                .finally(() => {
                    this.isSending = false
                })
        },
    },
}
